export const gptConversationRequestAdapter = (
  messages = [],
  message: string,
  role: string | null
) => {
  if (role === "system") {
    return { messages: [...messages, { role: "system", content: message }] };
  }
  return {
    messages: [
      ...messages,
      { role: "user", content: message },
      { role: "assistant", content: "" },
    ],
  };
};

export const filterSystemConversationMessages = (conversation) => {
  const filteredMessages = conversation.messages.filter(
    (message) => message.role !== "system"
  );
  return { messages: filteredMessages };
};
