import './nav-bar.component.scss'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectGPTConversationLoading } from '../../store/gptConversation/selectors'

const NavBarComponent = () => {
  const navigate = useNavigate()
  const loadingConversation = useSelector(selectGPTConversationLoading)

  const goHome = () => {
    !loadingConversation && navigate(`/`)
  }

  return (
    <div className="bg-nav bg-img-cover">
      <div className="fr-auto-layput container mx-auto p-4">
        <div className="cursor-pointer" onClick={goHome}>
          <Typography variant="h4">Learn2Prompt</Typography>
        </div>
        <div className="center-items">{/* Placeholder for buttons */}</div>
      </div>
    </div>
  )
}

export default NavBarComponent
