"use client";

import { useEffect, useMemo, useRef } from "react";

export const useCallbackRef = <
  T extends (...args: Parameters<T>) => ReturnType<T>
>(
  callback: T | undefined
): T => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(() => ((...args) => callbackRef.current?.(...args)) as T, []);
};
