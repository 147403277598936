import { Config } from "../../../auth/config";
import { lerwerkGPTAxios } from "../../../services/axios.config";
import { ConversationRequest } from "../models/caht.model";

export const getLernwerkGPTResult = async (mesageToGetKeywordsFrom: string) => {
  const response = await lerwerkGPTAxios.post("", {
    message: mesageToGetKeywordsFrom,
  });
  return response;
};

export async function conversationApi(
  conversation: ConversationRequest,
  abortSignal: AbortSignal
): Promise<Response> {
  const headers = new Headers({
    Authorization: `Bearer ${Config.bearerToken}`,
    "Content-Type": "application/json",
  });

  const response = await fetch(Config.chatCompletionUrl, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ messages: conversation.messages }),
    signal: abortSignal,
  });
  return response;
}
