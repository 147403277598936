import { useRoutes, BrowserRouter } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ChatView from "../views/chat-view/chat.view";
import LoginView from "../views/auth/login";
import NotFounView from "../views/NotFound/NotFound";
import SavedChatsView from "../views/saved-chats/saved-chats.view";
import HomeView from "../views/home-view/home.view";
import FooterComponent from "../components/footer/footer.component";
import TrainingView from "../views/training-view/training.view";
import MainPageLayout from "../layouts/page.layout";
import AllTrainingsView from "../views/all-trainings-view/all-trainings.view";

function AppRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <div>
          <HomeView />
          <br />
          <br />
          <FooterComponent />
        </div>
      ),
    },
    {
      path: "/chat/:trainingId?",
      element: (
        <MainPageLayout>
          <ChatView />
        </MainPageLayout>
      ),
    },
    {
      path: "/training/:trainingId/*",
      element: (
        <MainPageLayout>
          <TrainingView />
        </MainPageLayout>
      ),
      children: [
        {
          path: "chat",
          element: <ChatView />,
        },
      ],
    },
    {
      path: "/all-trainings",
      element: (
        <MainPageLayout>
          <AllTrainingsView />
        </MainPageLayout>
      ),
    },
    {
      path: "/saved-conversations",
      element: <SavedChatsView />,
    },
    {
      path: "*",
      element: <NotFounView />,
    },
  ]);
  return routes;
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <AppRoutes></AppRoutes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginView />
      </UnauthenticatedTemplate>
    </BrowserRouter>
  );
}
