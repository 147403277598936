import "./training-card.component.scss";
import { Button, Card, CardMedia, Chip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { learningTimeToText } from "./training-card.helper";
import { useDispatch } from "react-redux";
import { sendMessageGptActionSuccess } from "../../store/gptConversation/actions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type CardProps = {
  index: number;
  title: string;
  description: string;
  id: string;
  titleImage: string;
};

const TrainingCardComponent = (card: CardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const startTraining = (id: string) => {
    dispatch(sendMessageGptActionSuccess([]));
    navigate(`/training/${id}`);
  };

  // const thumb = getThumbnail(card.titleImage);

  return (
    <div className="item">
      <Card className="h-full v-auto-fr ">
        <CardMedia
          sx={{ height: 200, cursor: "pointer" }}
          image={`./images/thumbnails/thumbnail_${card.index % 18}.webp`}
          title={card.title}
          onClick={() => startTraining(card.id)}
        />

        <div className="p-5 h-full v-fr-auto">
          {/* header */}
          <div>
            <div>
              <Chip
                size="small"
                icon={<ScheduleIcon />}
                label={learningTimeToText("FIVE_TO_TWENTY_MIN")}
              />
            </div>
            <h1 className="text-xl py-3">{card.title}</h1>
          </div>
          {/* Footer buttons */}
          <div className="pt-6">
            <Button
              onClick={() => startTraining(card.id)}
              size="small"
              color="primary"
              variant="contained"
            >
              <ArrowForwardIcon className="mr-2" /> {t("start_training_cta")}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TrainingCardComponent;
