import { TextField, IconButton } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { styled } from "@mui/material";
import { ChangeEventHandler } from "react";

type Props = {
  handleSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const SearchBox = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "25px",
    border: "solid 1px black",
  },
}));

const TrainingSearch = ({ handleSearch }: Props) => {
  return (
    <div className="TextField-without-border-radius">
      <SearchBox
        fullWidth
        onChange={handleSearch}
        placeholder="Search here e.g. UX, Python, Product..."
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchOutlined />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default TrainingSearch;
