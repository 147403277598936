'use client'

import { useEffect, useMemo } from 'react'

export const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value?: string | number | readonly string[]
) => {
  const maxHeight = useMemo(
    () => (textAreaRef ? parseInt(getComputedStyle(textAreaRef).maxHeight) : 160),
    [textAreaRef]
  )

  const initialHeight = useMemo(
    () => (textAreaRef ? parseInt(getComputedStyle(textAreaRef).height) : 0),
    [textAreaRef]
  )
  const minHeight = useMemo(
    () =>
      textAreaRef
        ? parseInt(getComputedStyle(textAreaRef).minHeight || getComputedStyle(textAreaRef).height)
        : 40,
    [textAreaRef]
  )

  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px'
      textAreaRef.style.overflow = 'hidden'

      const scrollHeight = textAreaRef.scrollHeight

      textAreaRef.style.height = Math.max(scrollHeight, initialHeight, minHeight) + 'px'

      if (scrollHeight > maxHeight) {
        textAreaRef.style.overflow = 'auto'
      }
    }
  }, [textAreaRef, value, maxHeight, minHeight, initialHeight])
}
