import classnames, { ClassValue } from "clsx";
import { extendTailwindMerge, getDefaultConfig } from "tailwind-merge";

const twMerge = extendTailwindMerge({
  ...getDefaultConfig(),
});

/**
 *
 * @param args
 * @returns
 */
export const classNames = (...args: ClassValue[]) => {
  return twMerge(classnames(args));
};
