import { useTranslation } from "react-i18next";
import TrainingCardComponent from "../../../../components/training-card/training-card.component";
import "./home-training-section.component.scss";
import { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomeTrainingSectionComponent = ({ articles }) => {
  const [showMore, setShowMore] = useState(false);
  const ref = useRef(null);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/all-trainings");
  };

  const visibleArticles = showMore ? articles : articles.slice(0, 6);

  useEffect(() => {
    setShowMore(false);
  }, [i18n.language]);

  return (
    <div className="flex justify-center flex-col">
      <div>
        <div className="title text-4xl pb-3" ref={ref}>
          {t("trainings_title")}
        </div>
        <div className="text-xl pb-8">{t("trainings_subtitle")}</div>
      </div>

      <div className="py-3 px-1">
        <div className="training-section-layout">
          {visibleArticles?.map((card, index) => (
            <TrainingCardComponent
              key={index}
              index={index}
              title={card.title}
              description={card.description}
              id={card.id}
              titleImage={card.titleImage}
            />
          ))}
        </div>
        <div className="flex justify-center w-full py-8 lg:py-16">
          <Button color="secondary" variant="contained" onClick={handleClick}>
            {showMore ? t("trainings_hide") : t("trainings_show_more")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeTrainingSectionComponent;
