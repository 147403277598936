import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./tips-item.component.scss";

const TipsItemComponent = ({ item, index }) => {
  const visitLerwerkLink = () => {
    window.open(item.link, "_blank");
  };

  return (
    <div>
      <div className="tips-item-layout py-5">
        <img
          className="border-radius tips-item-img"
          src={`./images/tips_${(index % 6) + 1}.webp`}
          alt={item.title}
        />
        <div className="my-auto sm:ml-8">
          <div className="text-xl font-bold pb-3 h-tips-title-layout">
            <div>{item.title}</div>
            <div className="h-open-tip center-items">
              <OpenInNewIcon
                className="cursor-pointer"
                onClick={visitLerwerkLink}
              ></OpenInNewIcon>
            </div>
          </div>
          <div className="text-gray-500">
            <p className="tips-description">{item.description}</p>
          </div>
        </div>

        <div className="v-open-tip center-items">
          <OpenInNewIcon
            className="cursor-pointer"
            onClick={visitLerwerkLink}
          ></OpenInNewIcon>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default TipsItemComponent;
