import { forwardRef, useEffect, useRef } from 'react'
import { useControllableState } from '../../../../hooks/use-controllable-state.hook'
import { useAutosizeTextArea } from '../../../../hooks/use-autosize-textarea.hook'
import { useMergeRefs } from '../../../../hooks/use-merge-refs.hook'
import { classNames } from '../../../../utils/class-names'
import sanitizeHtml from 'sanitize-html'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useTranslation } from 'react-i18next'
import { handleFocusEnd } from '../../../../utils/focus-end'

export const ContentEditableDivComponent = forwardRef<
  HTMLTextAreaElement,
  React.ComponentPropsWithoutRef<'textarea'> & {
    onValueChange?: (value?: string | number | readonly string[] | any) => void
  }
>(
  (
    { className, value: valueProp, defaultValue, onValueChange, onChange, onKeyDown },
    forwardedRef,
  ) => {
    // need to fix types incompatibility
    const textareaRef = useRef<any>(null)
    const { t } = useTranslation()
    const [value] = useControllableState({
      prop: valueProp,
      defaultProp: defaultValue,
      onChange: onValueChange,
    })

    useEffect(() => {
      handleFocusEnd(textareaRef)
    })

    useAutosizeTextArea(textareaRef.current, value)

    const handleEnterKey = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onKeyDown(e)
        e.preventDefault()
      }
    }
    return (
      <div
        contentEditable
        onKeyDown={(e) => handleEnterKey(e)}
        className={classNames(
          'z-0 mt-1 flex !h-auto w-full flex-wrap overflow-scroll whitespace-pre-wrap rounded-md border border-gray-300 bg-white px-3 py-6 text-inherit shadow-sm invalid:border-pink-500 invalid:text-pink-600 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none dark:border-gray-600 dark:bg-transparent dark:text-white',
          className,
        )}
        ref={useMergeRefs(textareaRef, forwardedRef)}
      >
        <ReactMarkdown
          children={sanitizeHtml(value, {
            allowedTags: ['strong'],
            allowedAttributes: false,
          })}
          className="reactMarkDown"
          rehypePlugins={[rehypeRaw]}
        />

        {!value && (
          <div contentEditable={false} className="text-gray-400">
            {t('send_message')}
          </div>
        )}
      </div>
    )
  },
)

ContentEditableDivComponent.displayName = 'ContentEditableDivComponent'
