import "./footer.component.scss";

const FooterCreatedByComponent = ({ standAlone = false }) => {
  return (
    <div
      className={standAlone ? "text-center bg-black text-white px-2 py-12" : ""}
    >
      <small>
        <div>© 2024 All Rights Reserved</div>
        <div>Made by Exxeta | Privacy Policy</div>
      </small>
    </div>
  );
};

export default FooterCreatedByComponent;
