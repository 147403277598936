import { useTranslation } from "react-i18next";
import {
  publicUrls_en,
  publicUrls_de,
} from "../../../../assets/public-urls-data/public-urls";
import TipsItemComponent from "../../../../components/tips-item/tips-item.component";
import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { languages } from "../../../../assets/locales/translations";

const TipsSectionComponent = () => {
  const [showMore, setShowMore] = useState(false);
  const ref = useRef(null);

  const { t, i18n } = useTranslation();
  const language = languages[i18n.language];

  const tipsAndTricks =
    language === languages.de ? publicUrls_de : publicUrls_en;
  const visibleTipsAndTricks = showMore
    ? tipsAndTricks
    : tipsAndTricks.slice(0, 3);

  const handleClick = () => {
    showMore && ref.current?.scrollIntoView({ behavior: "smooth" });
    setShowMore((prevVal) => !prevVal);
  };

  return (
    <div>
      <div ref={ref} className="title text-4xl pb-3">
        {t("tips_tricks_title")}
      </div>
      <div className="text-xl pb-8">{t("tips_tricks_subtitle")}</div>

      {visibleTipsAndTricks.map((tip, index) => {
        return <TipsItemComponent key={index} item={tip} index={index} />;
      })}

      <div className="flex justify-center w-full py-8 lg:py-16">
        <Button color="secondary" variant="contained" onClick={handleClick}>
          {showMore ? t("tips_tricks_hide") : t("tips_tricks_show_more")}
        </Button>
      </div>
    </div>
  );
};

export default TipsSectionComponent;
