import { useTranslation } from "react-i18next";

import "./training-intro.component.scss";
import TrainingIntroCardComponent from "../training-intro-card/training-intro.component";

const DefaultTrainingIntroComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <TrainingIntroCardComponent index={0}>
        <div className="text-gray-600">
          {t(`training_intro.contetnt_description`)}
        </div>
        <br />
        <div className="font-bold">
          {t(`training_intro.contetnt_steps_title`)}
        </div>

        <div className="px-3 pt-2 text-gray-600">
          <ol className="list-disc">
            <li>{t("training_intro.contetnt_steps.0")}</li>
            <li>{t("training_intro.contetnt_steps.1")}</li>
            <li>{t("training_intro.contetnt_steps.2")}</li>
            <li>{t("training_intro.contetnt_steps.3")}</li>
          </ol>
        </div>
      </TrainingIntroCardComponent>
      <TrainingIntroCardComponent index={1}>
        <div className="text-gray-600">
          {t(`training_intro.goals_description`)}
        </div>
        <br />
        <div className="font-bold">{t(`training_intro.goals_steps_title`)}</div>

        <div className="px-3 pt-2 text-gray-600">
          <ol className="list-disc">
            <li>{t("training_intro.goals_steps.0")}</li>
            <li>{t("training_intro.goals_steps.1")}</li>
          </ol>
        </div>
      </TrainingIntroCardComponent>
      <TrainingIntroCardComponent index={2}>
        <div className="text-gray-600">
          {t(`training_intro.lets_go_description`)}
        </div>
      </TrainingIntroCardComponent>
    </>
  );
};

export default DefaultTrainingIntroComponent;
