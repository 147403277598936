export const publicUrls_en = [
  {
    title: "How to write better ChatGPT Prompts in 5 steps",
    description:
      "This article provides an introduction to the world of prompt creation for AI language models and shows how it can be used effectively to achieve the desired results.",
    link: "https://www.zdnet.com/article/how-to-write-better-chatgpt-prompts/",
  },
  {
    title: "LEAN Prompt Engineer: A Guide to Effective AI Communication",
    description:
      "This article introduces the LEAN approach to Prompt Engineering, aiming to streamline the process and make it more accessible.",
    link: "https://www.linkedin.com/pulse/lean-prompt-engineer-guide-effective-ai-communication-adam-m-victor-9pkbc",
  },
  {
    title:
      "AI for Everyone: A Beginner's Guide for Non-Technical Professionals",
    description:
      "This article provides a gentle introduction to AI for non-technical professionals, explaining the basics of how AI works and its potential benefits for various industries.",
    link: "https://www.linkedin.com/pulse/ai-dummies-beginners-guide-non-tech-savvy-anchal-ahuja/",
  },
  {
    title: "What is Prompt Engineering? A Detailed Guide For 2024",
    description:
      "This in-depth guide covers the intricacies of Prompt Engineering, exploring its art and science aspects.",
    link: "https://www.datacamp.com/blog/what-is-prompt-engineering-the-future-of-ai-communication#",
  },
  {
    title: "How AI is Reshaping the Future of Work",
    description:
      "This article takes a broader look at AI's impact on the workplace, discussing how it is automating tasks, creating new jobs, and changing the nature of work.",
    link: "https://www.forbes.com/sites/tracybrower/2023/07/09/ai-will-change-your-work-significantly-heres-how-to-respond/",
  },
  {
    title:
      "Demystifying Prompt Engineering: Unveiling the Art Behind Effective AI Communication",
    description:
      "This article offers a comprehensive overview of Prompt Engineering, highlighting its importance in achieving desired outcomes from AI, especially Large Language Models.",
    link: "https://www.linkedin.com/pulse/demystifying-prompt-engineering-unveiling-art-behind-effective-20t5c/?trk=article-ssr-frontend-pulse_more-articles_related-content-card",
  },
];

export const publicUrls_de = [
  {
    title: "Bessere ChatGPT Prompts in 5 Schritten",
    description:
      "Dieser Artikel bietet eine Einführung in die Welt der Prompt-Erstellung für KI-Sprachmodelle und zeigt, wie damit effektiv die gewünschten Ergebnisse erzielt werden können.",
    link: "https://www.zdnet.com/article/how-to-write-better-chatgpt-prompts-in-5-steps/",
  },
  {
    title:
      "LEAN Prompt Engineer: Ein Leitfaden für effektive Kommunikation mit KI",
    description:
      "Dieser Artikel stellt den LEAN-Ansatz für Prompt Engineering vor, mit dem Ziel den Prozess zu optimieren und leichter zugänglich zu machen.",
    link: "https://www.linkedin.com/pulse/lean-prompt-engineer-guide-effective-ai-communication-adam-m-victor-9pkbc/",
  },
  {
    title:
      "KI für jedermann: Ein Einsteigerhandbuch für nicht-technische Fachkräfte",
    description:
      "Dieser Artikel zeigt die Grundlagen der Funktionsweise der KI und ihre potenziellen Vorteile für verschiedene Branchen auf.",
    link: "https://www.linkedin.com/pulse/ai-dummies-beginners-guide-non-tech-savvy-anchal-ahuja/",
  },
  {
    title: "Was ist Prompt Engineering? Ein detaillierter Leitfaden für 2024",
    description:
      "Dieser Leitfaden befasst sich mit den Feinheiten des Prompt Engineering und beleuchtet seine künstlerischen und wissenschaftlichen Aspekte.",
    link: "https://www.datacamp.com/blog/what-is-prompt-engineering-the-future-of-ai-communication#",
  },
  {
    title: "Wie KI die Zukunft der Arbeit verändert",
    description:
      "Dieser Artikel wirft einen breiteren Blick auf die Auswirkungen der KI auf den Arbeitsplatz und beschreibt, wie sie Aufgaben automatisiert, neue Arbeitsplätze schafft und die Art der Arbeit verändert.",
    link: "https://www.forbes.com/sites/tracybrower/2023/07/09/ai-will-change-your-work-significantly-heres-how-to-respond/",
  },
  {
    title:
      "Das Phänomen Prompt Engineering: Die Kunst der effektiven KI-Kommunikation entschlüsselt",
    description:
      "Dieser Artikel beleuchtet den Einfluss von Prompt Engineering für das Erreichen der gewünschten Ergebnisse von KI hervor, insbesondere von Large Language Models.",
    link: "https://www.linkedin.com/pulse/demystifying-prompt-engineering-unveiling-art-behind-effective-20t5c/?trk=article-ssr-frontend-pulse_more-articles_related-content-card",
  },
];
