import "./App.scss";
import { useMsal } from "@azure/msal-react";
import AppRouter from "./router/App.router";
import { Provider } from "react-redux";
import store from "./store";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import translationEN from "./assets/locales/en/translation.json";
import translationDE from "./assets/locales/de/translation.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  DEFAULT_LANGUAGE,
  fallbackLanguage,
} from "./assets/locales/translations";

const theme = createTheme({
  palette: {
    primary: {
      main: "black",
      light: "white",
      dark: "black",
      contrastText: "white",
    },
    secondary: {
      main: "white",
      light: "black",
      dark: "white",
      contrastText: "black",
    },
  },
});

i18next.init({
  interpolation: { escapeValue: false },
});

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE.translationCode,
  fallbackLng: fallbackLanguage,
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const { inProgress } = useMsal();
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18next}>
        <Provider store={store}>
          {inProgress === "none" && <AppRouter />}
        </Provider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
