import { SET_ACTIVE_STEP } from './actionTypes'
import { SET_ACTIVE_TRAINING } from './actionTypes'

function ActiveStepReducer(state = { id: null, steps: 0 }, action) {
  switch (action.type) {
    case SET_ACTIVE_STEP:
      return {
        ...state,
        steps: state.steps + 1,
      }

    case SET_ACTIVE_TRAINING:
      if (action.id !== state.id) {
        return {
          ...state,
          id: action?.id,
          steps: 0,
        }
      }

      return state

    default:
      return state
  }
}

export default ActiveStepReducer
