import "./home.view.scss";
import { useEffect, useState } from "react";
import { getLernwerkArticlesSearch } from "./services/arcticles.service";
import HomeExplanationSectionComponent from "./components/home-explanation-section/home-explanation-section.component";
import TipsSectionComponent from "./components/tips-section/tips-section.component";
import HomeTrainingSectionComponent from "./components/home-training-section/home-training-section.component";
import HomeIntroComponent from "./components/home-intro/home-intro.component";
import { useTranslation } from "react-i18next";
import { languages } from "../../assets/locales/translations";

const HomeView = () => {
  const { i18n } = useTranslation();
  const [articles, setArtiles] = useState([]);
  const fetchData = async () => {
    const articles = await getLernwerkArticlesSearch(
      languages[i18n.language].lw_language_name
    );
    setArtiles(articles);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <HomeIntroComponent />
      <div className="container mx-auto xl:px-40 px-5">
        <HomeExplanationSectionComponent />
        <HomeTrainingSectionComponent articles={articles} />
        <br />
        <br />
        <TipsSectionComponent />
      </div>
    </>
  );
};

export default HomeView;
