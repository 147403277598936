import { Dispatch, SetStateAction } from "react";
import { Tag } from "./models/articles.model";
import TagComponent from "./tag.component";

type Props = {
  tags: Tag[];
  activeTags: Tag[];
  setActiveTags: Dispatch<SetStateAction<Tag[]>>;
};

const TagsList = ({ tags, activeTags, setActiveTags }: Props) => {
  return (
    <div>
      {tags.map((tag) => (
        <TagComponent
          key={tag.id}
          tag={tag}
          activeTags={activeTags}
          setActiveTags={setActiveTags}
        />
      ))}
    </div>
  );
};

export default TagsList;
