import TrainingCardComponent from "../../../components/training-card/training-card.component";
import { Article } from "./models/articles.model";

type Props = {
  pageNumber: number;
  OFFSET: number;
  filteredArticles: Article[];
};

const ArticlesList = ({ pageNumber, OFFSET, filteredArticles }: Props) => {
  return (
    <div className="flex justify-center flex-col">
      <div className="pt-4">
        {/* TODO: replace for translations */}
        <div className="title sm:text-6xl text-4xl pb-3">{"Recommended"}</div>
        <div className="text-xl sm:text-2xl pb-8">{"Hand picked for you"}</div>
      </div>

      <div className="py-3 px-1">
        <div className="training-section-layout">
          {filteredArticles
            .slice(
              (pageNumber - 1) * OFFSET,
              (pageNumber - 1) * OFFSET + OFFSET
            )
            .map((card: Article, index: number) => (
              <TrainingCardComponent
                key={index}
                index={index}
                title={card.title}
                description={card.description}
                id={card.id}
                titleImage={card.titleImage}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlesList;
