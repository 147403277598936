export const CHATS_KEY = "learn-gpt-chats-key";

export const saveNewChat = (messages) => {
  const chats = getSavedChats();
  const date = new Date();
  chats.push({ date: date.toISOString().split("T")[0], messages });
  setSavedChats(chats);
};

export const setSavedChats = (chats: any[]) => {
  localStorage.setItem(CHATS_KEY, JSON.stringify(chats));
};

export const getSavedChats = () => {
  const chats = localStorage.getItem(CHATS_KEY);
  if (!chats) return [];
  return JSON.parse(chats);
};
