import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Language,
  DEFAULT_LANGUAGE,
  translations,
  languages
} from "../../assets/locales/translations";

const LanguageSwitcherComponent = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] =
    useState<Language>(languages[i18n.language] || DEFAULT_LANGUAGE);

  

  const toggleMenuOpened = () => {
    isOpened ? setIsOpened(false) : setIsOpened(true);
  };

  const selectLanguage = (language: Language) => {
    setSelectedLanguage(language);
    setIsOpened(false);
    i18n.changeLanguage(language.translationCode);
  };

  return (
    <div className="absolute w-full">
      <div className="container mt-6 mx-auto px-5 flex justify-end">
        <div>
          <button
            onClick={() => toggleMenuOpened()}
            id="languages-button"
            data-dropdown-toggle="dropdown-languages"
            className="bg-black border py-2 rounded-full text-sm text-white pr-[5rem] pl-4"
            type="button">
            <div className="inline-flex items-center gap-2">
              <span
                className={`fi fis fi-${selectedLanguage.flagCode} rounded-full`}></span>
              {selectedLanguage.languageName}
            </div>
          </button>
          <div
            id="dropdown-languages"
            className={`z-10 ${
              isOpened ? "" : "hidden"
            } bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700`}>
            <ul
              className="py-2 text-xs text-gray-700 dark:text-gray-200"
              aria-labelledby="languages-button">
              {translations.map((language) => {
                return (
                  <li key={language.flagCode}>
                    <button
                      type="button"
                      onClick={() => selectLanguage(language)}
                      className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
                      <div className="inline-flex items-center gap-2">
                        <span
                          className={`fi fis fi-${language.flagCode} rounded-full`}></span>
                        {language.languageName}
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcherComponent;
