import { useMemo } from 'react'

type ReactRef<T> = React.Ref<T> | React.MutableRefObject<T>

const assignRef = <T = unknown>(ref: ReactRef<T> | undefined, value: T) => {
  if (ref == null) {
    return
  } else if (typeof ref === 'function') {
    ref(value)
  } else {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref.current = value
    } catch (error) {
      throw new Error(`Cannot assign value '${value}' to ref '${ref}'`)
    }
  }
}

export const useMergeRefs = <T>(...refs: (ReactRef<T> | undefined)[]) =>
  useMemo(() => {
    if (refs.every((ref) => ref === null)) {
      return null
    }

    return (node: T) => refs.filter((ref) => !!ref).forEach((ref) => assignRef(ref, node))
  }, [refs])
