import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { Config } from "./config";
export const msalConfig: Configuration = {
  auth: {
    clientId: Config.client_id,
    authority: Config.authority,
    redirectUri: '/'
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
