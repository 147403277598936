import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const TrainingsIntro = ({ handleBack }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="training-back-layout pb-12 pt-8">
        <span onClick={handleBack} className="cursor-pointer">
          <KeyboardArrowLeftIcon />
          <span className="underline">{t("back")}</span>
        </span>
      </div>
      <div className="pb-20">
        {/* TODO: replace for translations */}
        <span className="title text-6xl sm:text-[57px] leading-none">
          {"Explore"}
        </span>
        <br />
        <span className="title text-4xl sm:text-[96px] leading-none">
          {"Trainings"}
        </span>
      </div>
    </>
  );
};

export default TrainingsIntro;
