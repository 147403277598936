import { useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import TrainingIntroComponent from "../../components/training-intro/training-intro.component";
import ChatView from "../chat-view/chat.view";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import "./training.view.scss";
import { useEffect, useState } from "react";
import { getLernwerkArticleContentCached } from "../home-view/services/arcticles.service";
import { adaptArticleToTraining } from "../home-view/services/adapters/training.adapter";
import NotFounView from "../NotFound/NotFound";

const TrainingView = () => {
  const [loading, setLoading] = useState(false);

  const { trainingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [trainingData, setTrainingData] = useState(null);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const trainingArticle = await getLernwerkArticleContentCached(trainingId);
    setTrainingData(adaptArticleToTraining(trainingArticle));
    setLoading(false);
  };

  const handleBack = () => {
    if (location.pathname?.includes("chat")) {
      return navigate(location.pathname.replace("/chat", ""));
    }
    navigate("/");
  };

  return (
    <>
      <div className="training-back-layout pb-2">
        <span onClick={handleBack} className="cursor-pointer">
          <KeyboardArrowLeftIcon />
          <span className="underline">{t("back")}</span>
        </span>
        <small className="back-title">{trainingData?.title}</small>
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <TrainingIntroComponent
              trainingData={trainingData}
              loading={loading}
            />
          }
        />
        <Route path="/chat" element={<ChatView />} />
        <Route path="*" element={<NotFounView />} />
        {/* Handle any unmatched routes */}
      </Routes>
    </>
  );
};

export default TrainingView;
