import { Button } from "@mui/material";
import "./footer.component.scss";
import { CONTACT_US } from "./footer.variables";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useTranslation } from "react-i18next";
import FooterCreatedByComponent from "./footer-creted-by.component";

const FooterComponent = () => {
  const { t } = useTranslation();

  const contactUs = () => {
    window.open(CONTACT_US, "_blank");
  };

  return (
    <>
      <div className="text-center bg-black text-white px-2 py-12">
        <div className="title text-5xl pt-5">{t("footer_title")}</div>
        <div className="py-4 text-lg">{t("footer_subtitle")}</div>
        <br />
        <Button color="secondary" variant="contained" onClick={contactUs}>
          <MailOutlineIcon className="mr-3" /> {t("footer_contact")}
        </Button>

        <br />
        <br />
        <br />
        <FooterCreatedByComponent />
      </div>
      <div className="bg-black text-white text-right pr-2">
        <small>1.0.7</small>
      </div>
    </>
  );
};

export default FooterComponent;
