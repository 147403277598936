import { Chip } from "@mui/material";
import { Tag } from "./models/articles.model";
import { Dispatch, SetStateAction } from "react";

type Props = {
  tag: Tag;
  activeTags: Tag[];
  setActiveTags: Dispatch<SetStateAction<Tag[]>>;
};

const TagComponent = ({ tag, activeTags, setActiveTags }: Props) => {
  return (
    <Chip
      key={tag.id}
      sx={{
        margin: "0 17px 20px 0",
        fontSize: "16px",
        padding: "4px 8px",
        border: activeTags?.includes(tag)
          ? "solid 1px #6200EE"
          : "solid 1px #000",
        color: activeTags?.includes(tag) ? "#6200EE" : "#000",
        background: activeTags?.includes(tag) ? "#D4BFF9" : "#fff",
      }}
      variant="outlined"
      onClick={(_) => {
        activeTags?.includes(tag)
          ? setActiveTags((prevValue) =>
              prevValue.filter((prevTag) => prevTag !== tag)
            )
          : setActiveTags((prevValue) => [...prevValue, tag]);
      }}
      label={tag.name}
    />
  );
};

export default TagComponent;
