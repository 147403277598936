import { useTranslation } from "react-i18next";
import "./home-explanation-section.component.scss";

const HomeExplanationSectionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="text-xl py-12 container m-auto w-full flex justify-center">
      <div className="flex w-full xl:w-4/5 bg-[#121212] text-white px-8 py-8 gap-8">
        <div className="hidden lg:block w-[600px] p-8 flex flex-col justify-center align-center">
          <img className="w-full" src={`./images/banner.png`} alt="Banner" />
        </div>
        <div className="flex flex-col justify-center">
          <div className="lg:pl-8">{t("home_explanation_1st_section")}</div>
          <br />
          <div>{t("home_explanation_2nd_section")}</div>
        </div>
      </div>
    </div>
  );
};

export default HomeExplanationSectionComponent;
