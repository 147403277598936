import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Config } from "../../auth/config";
import { msalInstance } from "../../auth/msal.config";

export const LoginView = () => {
  const navigate = useNavigate();

  const login = async () => {
    try {
      await msalInstance.loginRedirect({ scopes: Config.appScopes });
      navigate("/", { replace: true });
    } catch (error) {
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full p-10 shadow-lg rounded-md  max-w-md space-y-8">
        <div className="space-y-6">
          <div className="text-center">Single Sign On</div>
          <div>
            <button
              type="button"
              onClick={login}
              className="rounded-md w-full border bg-black py-2 px-4 text-sm font-medium text-white hover:bg-gray-700 rounded-full"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginView;
