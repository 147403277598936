import { getLernwerkGPTResult } from "../../views/chat-view/services/gpt-api.service";

import {
  lernwerkGptActionSuccess,
  lernwerkGptActionFailure,
  lernwerkGptStartedAction,
} from "./actions";

export const getLernwerkGPTResultTrunk = (mesageToGetKeywordsFrom: string) => {
  if (!mesageToGetKeywordsFrom) return;
  return async (dispatch) => {
    dispatch(lernwerkGptStartedAction());
    try {
      const res = await getLernwerkGPTResult(mesageToGetKeywordsFrom);
      dispatch(lernwerkGptActionSuccess(res.data.results || []));
    } catch (error) {
      dispatch(lernwerkGptActionFailure(error.message));
    }
  };
};
