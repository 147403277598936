import "./chat.view.scss";

import ChatPanelComponent from "./components/chat-panel/chat-panel.component";

const ChatView = () => {
  return (
    <div className="chat-view-layout py-5 h-[calc(100vh-(159px+78px+58px))] max-h-[calc(100vh-(159px+78px+58px))]">
      <ChatPanelComponent />
    </div>
  );
};

export default ChatView;
