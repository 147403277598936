export type Language = {
    flagCode: string;
    languageName: string;
    lw_language_name: string;
    translationCode: string;
}

const german: Language = {
    flagCode: "de",
    languageName: "deutsch",
    lw_language_name: "GERMAN",
    translationCode: "de",
} 

const english: Language = {
    flagCode: "gb",
    languageName: "english",
    lw_language_name: "ENGLISH",
    translationCode: "en",
}

export const translations: Language[] = [german, english];

export const DEFAULT_LANGUAGE: Language = german;

export const fallbackLanguage: string = german.translationCode;

export const languages = {
    "en": english,
    "de": german
}