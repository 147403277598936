import { useSelector } from 'react-redux'
import {
  selectActiveStep,
  selectGPTConversation,
} from '../../../../store/gptConversation/selectors'
import ChatInputComponent from '../chat-input/chat-input.component'
import './chat-panel.component.scss'
import ChatItemComponent from '../chat-item/chat-item.component'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TrainingStepperComponent from '../../../../components/training/training-stepper/training-stepper.component'
import { getLernwerkArticleContentCached } from '../../../home-view/services/arcticles.service'
import { adaptArticleToTraining } from '../../../home-view/services/adapters/training.adapter'
import { useParams } from 'react-router-dom'
import { setActiveStepAction } from '../../../../store/gptConversation/actions'
import { useDispatch } from 'react-redux'

const ChatPanelComponent = () => {
  const conversationState = useSelector(selectGPTConversation)
  const activeStep = useSelector(selectActiveStep)
  const [trainingData, setTrainingData] = useState<any>()
  const [trainerLoading, setTrainerLoading] = useState(false)

  const dispatch = useDispatch()

  const setActiveStep = () => {
    dispatch(setActiveStepAction())
  }

  const { t } = useTranslation()
  const { trainingId } = useParams()

  const fetchData = async () => {
    const trainingArticle = await getLernwerkArticleContentCached(trainingId)
    setTrainingData(adaptArticleToTraining(trainingArticle))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const smoothScrollToMessage = (delay: number) => {
    setTimeout(() => {
      document
        .getElementById('chat-messages-container')
        ?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, delay)
  }

  useEffect(() => {
    smoothScrollToMessage(200)
  }, [conversationState])

  useEffect(() => {
    smoothScrollToMessage(1200)
  }, [trainerLoading])

  return (
    <>
      {trainingId && (
        <div>
          <TrainingStepperComponent trainingData={trainingData} activeStep={activeStep} />
        </div>
      )}
      <div className="chat-panel-layout border-radius h-full overflow-y-hidden bg-gray-100 p-3">
        <div className="overflow-y-auto p-3">
          <div>
            {!conversationState.conversation?.length && (
              <div className="hello-chat text-center">
                <Typography sx={{ color: 'lightgray' }} variant="h4">
                  {t('learn2prompt_greeting')}
                </Typography>
              </div>
            )}
            {conversationState.conversation?.map((m, index) => (
              <ChatItemComponent
                processing={
                  (trainerLoading || conversationState.loading) &&
                  conversationState.conversation.length === index + 1
                }
                message={m}
                key={index}
                setTrainerLoading={setTrainerLoading}
              />
            ))}
          </div>
          <div id="chat-messages-container"></div>
        </div>
        <ChatInputComponent
          trainingData={trainingData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          trainerLoading={trainerLoading}
          setTrainerLoading={setTrainerLoading}
        />
      </div>
    </>
  )
}

export default ChatPanelComponent
