import FooterCreatedByComponent from "../components/footer/footer-creted-by.component";
import NavBarComponent from "../components/nav-bar/nav-bar.component";
import "./page.layout.scss";

const MainPageLayout = ({ children }) => {
  return (
    <div className="main-page-layout">
      <div>
        <NavBarComponent />
        <div className="h-full container mx-auto p-3">{children}</div>
      </div>
      <div>
        <br />
        <FooterCreatedByComponent standAlone={true} />
      </div>
    </div>
  );
};

export default MainPageLayout;
