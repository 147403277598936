import {
  LERWERK_GPT_FAILURE,
  LERWERK_GPT_START,
  LERWERK_GPT_SUCCESS,
} from "./actionTypes";

export interface LernwerkResultsStateInterface {
  data: any[];
  error;
  loading: boolean;
}

export const initialState: LernwerkResultsStateInterface = {
  data: null,
  error: null,
  loading: false,
};

function LernwerkGPTReducer(
  state: LernwerkResultsStateInterface = initialState,
  action
) {
  switch (action.type) {
    case LERWERK_GPT_START:
      return {
        ...state,
        loading: true,
      };
    case LERWERK_GPT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: [...action.data],
      };
    case LERWERK_GPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      };
    default:
      return state;
  }
}
export default LernwerkGPTReducer;
