import { configureStore } from "@reduxjs/toolkit";
import GPTMessageReducer from "./gptConversation/input-message-reducer";
import GPTConversationReducer, {
  GPTConversationStateInterface,
} from "./gptConversation/reducers";
import LernwerkGPTReducer, {
  LernwerkResultsStateInterface,
} from "./searchApiResults/reducers";
import ActiveStepReducer from "./gptConversation/active-steps-reducer";

export interface AppStateInterface {
  activeStep: any;
  steps: any;
  gptConversation: GPTConversationStateInterface;
  lerwerkResults: LernwerkResultsStateInterface;
  inputMessage;
}

const store = configureStore({
  reducer: {
    gptConversation: GPTConversationReducer,
    lerwerkResults: LernwerkGPTReducer,
    inputMessage: GPTMessageReducer,
    activeStep: ActiveStepReducer,
  },
});

export default store;
