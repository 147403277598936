import { classNames } from "../../utils/class-names";
import "./simple-loader.component.scss";

export enum ColorsEnum {
  BLACK = "black",
  WHITE = "white",
}

type Props = {
  color?: ColorsEnum;
};

const SimpleLoader = ({ color }: Props) => {
  return (
    <span className="pl-3">
      <div
        className={classNames(
          "lds-ellipsis",
          color === ColorsEnum.WHITE ? "white" : "black"
        )}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </span>
  );
};

export default SimpleLoader;
