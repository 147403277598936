export interface TrainingStepInterface {
  title: string
  description: string
  prompt: string
}

export interface Content {
  title: string
  context: HTMLDivElement
}

export interface TrainingInterface {
  id: string
  title: string
  description: Content[]
  steps: TrainingStepInterface[]
}

export const adaptArticleToTraining = (article): TrainingInterface => {
  return {
    id: article.id,
    title: article.title,
    description: adaptDescriptionElementToBlocks(article.content).slice(0, 3) || [],
    steps: article.pathElements?.map((pE) => adaptPathElementToTrainingStep(pE)) || [],
  }
}

export const adaptDescriptionElementToBlocks = (content): Content[] => {
  let blocks = []
  const headingRegex = /<h[1-6]>/g
  const closingHeadingRegex = /<\/h[1-6]>/g

  const blockContents = content.split(headingRegex).slice(1)

  blockContents.forEach((blockContent) => {
    const title = blockContent.split(closingHeadingRegex)[0]
    const restOfTheContent = blockContent.split(closingHeadingRegex)[1]

    if (title && restOfTheContent) {
      blocks = [...blocks, { title, content: restOfTheContent }]
    }
  })

  return blocks
}

export const adaptPathElementToTrainingStep = (pathElement): TrainingStepInterface => {
  const newHtml = document.createElement('div')
  newHtml.innerHTML = pathElement.placeholderDescription
  const allLinks = newHtml.querySelectorAll('a')

  return {
    description: pathElement.placeholderDescription,
    title: pathElement.placeholderTitle,
    prompt: allLinks[0]?.innerHTML,
  }
}
