import { AppStateInterface } from '..'

export const selectGPTConversation = (state: AppStateInterface) => state.gptConversation
export const selectGPTConversationMessages = (state: AppStateInterface) =>
  state.gptConversation?.conversation || []

export const selectGPTConversationLoading = (state: AppStateInterface) =>
  state.gptConversation?.loading

export const selectGPTMessageInput = (state: AppStateInterface) => state.inputMessage?.message

export const selectActiveStep = (state: AppStateInterface) => state.activeStep?.steps
