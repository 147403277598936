import { Config } from "../auth/config";
import { msalInstance } from "../auth/msal.config";
import { gptFunctionsApi } from "./axios.config";

export function lerwerkInterceptor() {
  gptFunctionsApi.interceptors.request.use(async (request) => {
    try {
      const { accessToken } = await msalInstance.acquireTokenSilent({
        scopes: Config.appScopes,
        account: msalInstance.getAllAccounts()[0],
      });
      request.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      } as any;
    } catch (error) {
      await msalInstance.loginRedirect({ scopes: Config.appScopes });
      return error;
    }

    return request;
  });
}
// export {};
