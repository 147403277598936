import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import './training-intro.component.scss'
import { useNavigate } from 'react-router-dom'
import TrainingIntroCardComponent from '../training-intro-card/training-intro.component'
import DefaultTrainingIntroComponent from './default-training-intro'
import ReactHtmlParser from 'html-react-parser'
import { useDispatch } from 'react-redux'
import { setActiveTrainingAction } from '../../store/gptConversation/actions'
import { useEffect } from 'react'

const TrainingIntroComponent = ({ trainingData, loading = false }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const startTraining = () => {
    navigate(`chat`)
  }

  useEffect(() => {
    trainingData?.id && dispatch(setActiveTrainingAction(trainingData?.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingData])

  const description = trainingData?.description

  return (
    <div className="h-full overflow-auto px-2 pt-2">
      {loading ? (
        <Typography className="p-2" variant="h5">
          Loading.
        </Typography>
      ) : (
        <>
          <div className="training-intro-title-layout">
            <Typography variant="h4">{trainingData?.title}</Typography>
            <div className="start-training-button">
              <Button
                onClick={startTraining}
                className="whitespace-nowrap"
                variant="contained"
                color="primary"
              >
                <ArrowForwardIcon className="mr-2" /> {t('start_training_cta')}
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div className="training-intro-content-layout pb-2">
            {description?.length === 3 ? (
              description?.map((desc, index) => {
                return (
                  <TrainingIntroCardComponent index={index} key={index} title={desc?.title}>
                    {ReactHtmlParser(desc?.content)}
                  </TrainingIntroCardComponent>
                )
              })
            ) : (
              <DefaultTrainingIntroComponent />
            )}
          </div>
          <div className="block py-8 sm:hidden">
            <Button
              onClick={startTraining}
              className="whitespace-nowrap"
              variant="contained"
              color="primary"
            >
              <ArrowForwardIcon className="mr-2" /> {t('start_training_cta')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default TrainingIntroComponent
