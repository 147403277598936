export type ChatMessage = {
  role: string
  content: string
  end_turn?: boolean
}

export enum ChatCompletionType {
  ChatCompletion = 'chat.completion',
  ChatCompletionChunk = 'chat.completion.chunk',
}

export type ConversationRequest = {
  messages: ChatMessage[]
}

export type ChatResponse = {
  id: string
  model: string
  created: number
  object: ChatCompletionType
  choices: ChatResponseChoice[]
  error?: any
}
export type ChatResponseChoice = {
  messages: ChatMessage[]
}

export enum IconAlts {
  system = 'L2P instructor',
  user = 'User',
  assistant = 'ChatGPT',
}
