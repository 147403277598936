import {
  SEND_MESSAGE_GPT_FAILURE,
  SEND_MESSAGE_GPT_START,
  SEND_MESSAGE_GPT_SUCCESS,
  SEND_MESSAGE_GPT_END,
  SET_MESSAGE_GPT_INPUT,
  SET_ACTIVE_STEP,
  SET_ACTIVE_TRAINING,
} from './actionTypes'

// fetch data
export const sendMessageGptStartedAction = () => ({
  type: SEND_MESSAGE_GPT_START,
})
export const sendMessageGptEndedAction = () => ({
  type: SEND_MESSAGE_GPT_END,
})
export const sendMessageGptActionSuccess = (conversation) => ({
  type: SEND_MESSAGE_GPT_SUCCESS,
  conversation: [...conversation],
})
export const sendMessageGptActionFailure = (error) => ({
  type: SEND_MESSAGE_GPT_FAILURE,
  error: { error },
})

export const setMessageGptInputAction = (message) => ({
  type: SET_MESSAGE_GPT_INPUT,
  message,
})

export const setActiveStepAction = () => ({
  type: SET_ACTIVE_STEP,
})

export const setActiveTrainingAction = (id) => ({
  type: SET_ACTIVE_TRAINING,
  id,
})
