import { Card, Typography } from "@mui/material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { useTranslation } from "react-i18next";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

const TrainingIntroCardComponent = ({
  index,
  children = null,
  title = null,
}) => {
  const { t } = useTranslation();
  const renderTitle = () => {
    switch (index) {
      case 0:
        return (
          <>
            <LibraryBooksIcon className="mr-2" />
            <>{title || t(`training_intro.contetnt`)}</>
          </>
        );
      case 1:
        return (
          <>
            <CrisisAlertIcon className="mr-2" />
            {title || t(`training_intro.goals`)}
          </>
        );
      case 2:
        return (
          <>
            <RocketLaunchIcon className="mr-2" />
            {title || t(`training_intro.lets_go`)}
          </>
        );
      default:
        return (
          <>
            <LibraryBooksIcon className="mr-2" />
            {title || t(`training_intro.contetnt`)}
          </>
        );
    }
  };

  return (
    <Card className="p-9 text-gray">
      <Typography variant="h4">{renderTitle()}</Typography>
      <br />
      {children}
    </Card>
  );
};

export default TrainingIntroCardComponent;
