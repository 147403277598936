export const handleFocusEnd = (ref) => {
  if (ref.current && ref.current.contentEditable === 'true') {
    ref.current.focus()
    const range = document.createRange()
    const selection = window.getSelection()
    range.selectNodeContents(ref.current)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  }
}
