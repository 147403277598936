import { useState, useEffect, useMemo, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getLernwerkArticlesSearch } from "../home-view/services/arcticles.service";
import { languages } from "../../assets/locales/translations";
import TrainingSearch from "./components/training-search.component";
import { Pagination } from "@mui/material";
import TagsList from "./components/tags-list.component";
import ArticlesList from "./components/articles-list.component";
import TrainingsIntro from "./components/trainings-intro.component";
import { Article, Tag } from "./components/models/articles.model";

const OFFSET = 6;

const AllTrainingsView = () => {
  const [activeTags, setActiveTags] = useState<Tag[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [filteredArticles, setFilteredArticles] = useState<Article[]>([]);

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const tags = useMemo(() => {
    const uniqueTags = articles
      .map((article) => article.tags) // map tags from all articles
      .flat() // single array
      .filter((tag, index, tags) => {
        // get rid of duplicates
        return tags.findIndex((t) => t.name === tag.name) === index;
      });

    return uniqueTags;
  }, [articles]);

  const filterArticles = (articles: Article[], tagsName: string[]) => {
    if (tagsName.length === 0) return articles;
    return articles.filter((article) => {
      return article.tags.some((tag) => tagsName.includes(tag.name));
    });
  };

  const fetchData = async () => {
    const articles = await getLernwerkArticlesSearch(
      languages[i18n.language].lw_language_name
    );
    setArticles(articles);
  };

  useEffect(() => {
    setPageNumber(1);
    const tagNames = activeTags?.map((tag) => tag.name);
    setFilteredArticles(filterArticles(articles, tagNames));
  }, [activeTags, articles]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const handleBack = () => {
    if (location.pathname?.includes("chat")) {
      return navigate(location.pathname.replace("/chat", ""));
    }
    navigate("/");
  };

  const handlePagination = (_, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  // search done on client, no debouncing needed
  const handleSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPageNumber(1);
    const searchValue = event.target?.value.toLowerCase();
    const tagNames = activeTags.map((tag) => tag.name);

    setFilteredArticles(
      filterArticles(articles, tagNames).filter((article) =>
        article.title.toLowerCase().includes(searchValue)
      )
    );
  };

  return (
    <>
      <div className="container mx-auto xl:px-40 px-5">
        <TrainingsIntro handleBack={handleBack} />
        <div className="pb-12">
          <TrainingSearch handleSearch={handleSearch} />
        </div>
        <TagsList
          tags={tags}
          activeTags={activeTags}
          setActiveTags={setActiveTags}
        />
        {/* list of articles section */}
        <ArticlesList
          OFFSET={OFFSET}
          filteredArticles={filteredArticles}
          pageNumber={pageNumber}
        />
        <div className="flex w-full justify-center">
          <Pagination
            count={Math.ceil(filteredArticles.length / OFFSET)}
            page={pageNumber}
            onChange={handlePagination}
          />
        </div>
      </div>
    </>
  );
};

export default AllTrainingsView;
