import {
  SEND_MESSAGE_GPT_END,
  SEND_MESSAGE_GPT_FAILURE,
  SEND_MESSAGE_GPT_START,
  SEND_MESSAGE_GPT_SUCCESS,
} from "./actionTypes";

export interface Message {
  role: string;
  content: string;
}
export interface GPTConversationStateInterface {
  conversation: Message[];
  error;
  loading: boolean;
}

export const initialState: GPTConversationStateInterface = {
  conversation: null,
  error: null,
  loading: false,
};

function GPTConversationReducer(
  state: GPTConversationStateInterface = initialState,
  action
) {
  switch (action.type) {
    case SEND_MESSAGE_GPT_START:
      return {
        ...state,
        loading: true,
      };
    case SEND_MESSAGE_GPT_END:
      return {
        ...state,
        loading: false,
      };
    case SEND_MESSAGE_GPT_SUCCESS:
      return {
        ...state,
        error: null,
        conversation: [...action.conversation],
      };
    case SEND_MESSAGE_GPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      };
    default:
      return state;
  }
}
export default GPTConversationReducer;
