import { DeleteOutline, OpenInNew } from "@mui/icons-material";
import { Card, CardContent, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getSavedChats,
  setSavedChats,
} from "../../services/localstorage.service";
import { setOldConversationTrunk } from "../../store/gptConversation/trunk";
import { useTranslation } from "react-i18next";

export const SavedChatsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setChats(getSavedChats());
  }, []);

  const deleteChat = (index) => {
    chats.splice(index, 1);
    setChats([...chats]);
    setSavedChats(chats);
  };

  const goToChat = (messages) => {
    dispatch(setOldConversationTrunk(messages) as any);
    navigate(`/`);
  };
  return (
    <div>
      {!chats?.length && (
        <div className="text-center">
          <br />
          <br />
          {t("no_saved_conversations")}
        </div>
      )}

      {chats?.map((ch, index) => (
        <div key={index} className="mb-3">
          <Card>
            <CardContent>
              <div className="fr-auto-layput">
                <div> {ch.date}</div>
                <div>
                  <Tooltip title="Go to conversation">
                    <IconButton onClick={() => goToChat(ch.messages || [])}>
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete conversation">
                    <IconButton onClick={() => deleteChat(index)}>
                      <DeleteOutline color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div>
                {ch.messages?.length && (
                  <div>
                    <div>Last message:</div>
                    <div>
                      {ch.messages[ch.messages.length - 1].role} -{" "}
                      {ch.messages[ch.messages.length - 1].content}
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default SavedChatsView;
