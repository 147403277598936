import { useTranslation } from "react-i18next";
import LanguageSwitcherComponent from "../../../../components/language-switcher/language-switcher.component";
import "./home-intro.component.scss";

const HomeIntroComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <LanguageSwitcherComponent />
      <div className="home-intro-container bg-img-cover center-items text-3xl">
        <div>
          <div className="welcome-font title">{t("welcome_heading")}</div>
          <div className="logo-font title">Learn2Prompt</div>
          <div className="slogan-font bg-white p-2 w-fit">{t("tagline")}</div>
        </div>
      </div>
    </div>
  );
};

export default HomeIntroComponent;
