import {
  LERWERK_GPT_FAILURE,
  LERWERK_GPT_START,
  LERWERK_GPT_SUCCESS,
} from "./actionTypes";

export const lernwerkGptStartedAction = () => ({
  type: LERWERK_GPT_START,
});
export const lernwerkGptActionSuccess = (data) => ({
  type: LERWERK_GPT_SUCCESS,
  data: [...data],
});

export const lernwerkGptActionFailure = (error) => ({
  type: LERWERK_GPT_FAILURE,
  error: { error },
});
