import "./training-stepper.component.scss";

const TrainingStepperComponent = ({ trainingData, activeStep }) => {
  return (
    <div className="text-center pt-2 px-3">
      {trainingData?.steps.map((step, index) => (
        <div
          key={index}
          className={`mb-2 px-3 py-1 shadow border-radius cursor-default ${
            index === activeStep && "bg-black text-white"
          }`}
        >
          {index + 1}
        </div>
      ))}
    </div>
  );
};

export default TrainingStepperComponent;
