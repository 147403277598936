import { SET_MESSAGE_GPT_INPUT } from "./actionTypes";

function GPTMessageReducer(state = { message: "" }, action) {
  switch (action.type) {
    case SET_MESSAGE_GPT_INPUT:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
}

export default GPTMessageReducer;
