import './chat-input.component.scss'
import { IconButton } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { sendGPTmessageTrunk } from '../../../../store/gptConversation/trunk'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectGPTConversationLoading,
  selectGPTConversationMessages,
} from '../../../../store/gptConversation/selectors'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../../../utils/class-names'
import { useAutosizeTextArea } from '../../../../hooks/use-autosize-textarea.hook'
import { Send } from '@mui/icons-material'
import sanitizeHtml from 'sanitize-html'
import { ContentEditableDivComponent } from '../textarea/contentEditableDiv.component'

// TODO: Exclude textarea with all some logic and states from this component
const ChatInputComponent = ({
  trainingData,
  setActiveStep,
  activeStep,
  trainerLoading,
  setTrainerLoading,
}) => {
  const dispatch = useDispatch()
  const textareaRef = useRef<any>(null)
  const loadingConversation = useSelector(selectGPTConversationLoading)
  const conversationMessages = useSelector(selectGPTConversationMessages)
  const [userSentInput, setUserSentInput] = useState(true)
  const [inputVal, setInputVal] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    if (!loadingConversation && document.activeElement !== textareaRef.current) {
      textareaRef.current?.focus()
    }
  }, [loadingConversation])

  useAutosizeTextArea(textareaRef.current, inputVal)

  useEffect(() => {
    if (shouldSendSystemMessage()) {
      setTrainerLoading(true)
      conversationMessages.at(-1)?.role !== 'system' &&
        sendSystemMessage(trainingData?.steps[activeStep]?.description)
      setInputVal(trainingData?.steps[activeStep]?.prompt)
      setUserSentInput(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, loadingConversation, trainingData, userSentInput])

  const shouldSendSystemMessage = () => {
    if (!conversationMessages.length) return true
    return !loadingConversation && userSentInput && activeStep <= trainingData?.steps.length
  }

  const sendSystemMessage = (promptData) => {
    if (!promptData) return
    dispatch(sendGPTmessageTrunk(promptData, 'system') as any)
  }

  const sendMEssage = async () => {
    const message = sanitizeHtml(textareaRef.current.innerHTML, {
      allowedTags: ['strong'],
      allowedAttributes: false,
    })
    dispatch(sendGPTmessageTrunk(message) as any)
    setInputVal('')
    setUserSentInput(true)
    setActiveStep()
  }

  return (
    <div className=" border-radius  bg-white px-3 py-2">
      {/* <FormControl fullWidth disabled={loadingConversation}> */}
      <div className="flex items-center">
        <ContentEditableDivComponent
          className={classNames(
            'mt-0 h-9 max-h-48 min-h-[36px] w-full resize-none self-center overflow-hidden border-none bg-transparent px-2 py-1 leading-7 shadow-none outline-none focus:ring-0 disabled:bg-transparent',
            inputVal && 'user-message',
          )}
          value={inputVal}
          disabled={loadingConversation || trainerLoading}
          ref={textareaRef}
          onKeyDown={sendMEssage}
          placeholder={t('send_message')}
        />
        <IconButton disabled={loadingConversation || trainerLoading} onClick={sendMEssage}>
          <Send />
        </IconButton>
      </div>
      {/* </FormControl> */}
    </div>
  )
}

export default ChatInputComponent
